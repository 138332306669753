/**
 * @file beethoven.ts
 * Fetches attributes for a URL so it can be presented on Padlet.
 */
import environment from '@@/bits/environment'
import { captureMessage } from '@@/bits/error_tracker'
import type { JsonAPIResource, Link } from '@padlet/arvo'
import { fetchLink } from '@padlet/arvo'
import type { BeethovenDisplayAttributes, BeethovenDisplayOptions } from '@padlet/beethoven-client'
import { getDisplayAttributes as originalGetDisplayAttributes } from '@padlet/beethoven-client'
import { memoize } from 'es-toolkit'

const memoizedArvoFetchLink = memoize(fetchLink)

/**
 * URLs that have an X-Frame-Options header that disallow a URL from being displayed in an iframe would
 * break our PDF viewer. We proxy the URL via a Cloudflare worker that removes the X-Frame-Options header.
 *
 * TODO: move all the conditional pdf embedding into @padlet/beethoven-client.
 * @return {string} The proxied URL.
 */
function iframeDisplayableProxiedPdfUrl(url: string): string {
  return `https://xp.padletcdn.com/proxy?url=${encodeURIComponent(url)}`
}

function conditionallyReplacePdfEmbedCode(displayAttributes: BeethovenDisplayAttributes): BeethovenDisplayAttributes {
  // return displayAttributes // uncomment this to not load pdf through /beethoven/pdf-viewer.
  // only pdfs
  if (displayAttributes.content_subcategory !== 'pdf') return displayAttributes

  let pdfViewerHost = 'padlet.com'
  if (environment === 'development') {
    pdfViewerHost = 'padlet.dev'
  } else if (environment === 'staging') {
    pdfViewerHost = 'padlet.io'
  }

  // Previously when we were using PSPDFKit, the hostname for the iframe had to be padlet.com,
  // because the library was licensed per host and we only bought it for padlet.com.
  // Now, we can set the host to the current host.
  displayAttributes.embed_code = `<iframe class="wb-embed-content" frameborder="0" style="border:0;" width="1024" height="768" src="https://${pdfViewerHost}/beethoven/pdf-viewer?url=${encodeURIComponent(
    iframeDisplayableProxiedPdfUrl(displayAttributes.url),
  )}";></iframe>`
  return displayAttributes
}

async function getDisplayAttributes(
  url: string,
  options: BeethovenDisplayOptions = {},
  attachmentLink: Link | null | undefined = null,
): Promise<BeethovenDisplayAttributes | null> {
  const displayOptions = options
  const fetchLink = memoizedArvoFetchLink
  try {
    let original: BeethovenDisplayAttributes
    if (attachmentLink !== null && attachmentLink !== undefined) {
      original = originalGetDisplayAttributes(attachmentLink, displayOptions)
    } else {
      const link = await fetchLink(url)
      if (link == null) {
        captureMessage('Fetch link from Arvo returned null', { context: { url } })
        return null
      }
      original = originalGetDisplayAttributes((link.data as JsonAPIResource<Link>).attributes, displayOptions)
    }
    return conditionallyReplacePdfEmbedCode(original)
  } catch (e) {
    if (e.status === 400) {
      return JSON.parse(e.message)
    }
    return null
  }
}

/**
 * Allows loading PSPDFKit for Web on our electron app
 * We don't want to load PSPDFKit for Electron. If they detects 'Electron/' they will try to load the wrong version.
 * @see https://pspdfkit.com/guides/web/knowledge-base/override-user-agent/
 */
function overrideUserAgentForPspdfkit(): void {
  const currentUserAgent = navigator.userAgent
  Object.defineProperty(navigator, 'userAgent', { get: () => currentUserAgent.replace(/Electron\/.*\s/g, '') })
}

export { getDisplayAttributes, memoizedArvoFetchLink, originalGetDisplayAttributes, overrideUserAgentForPspdfkit }
